import { pick } from "lodash-es";
import type { ReactNode } from "preact/compat";
import { useContext, createContext } from "preact/compat";
import { useMemo, useState } from "preact/hooks";
import hebrewTranslations from "./hebrew.json";

type ContextValue = {
	readonly translate: (
		value: string,
		variables?: Record<string, unknown>,
	) => string;
	readonly languageOptions: readonly string[];
	readonly setCurrentLanguage: (newLanguage: string) => void;
	readonly currentLanguage: string;
};

const I18nContext = createContext<ContextValue | undefined>(undefined);

const translations = [
	{ name: "English", code: "en" },
	{ name: "עברית (Hebrew)", code: "he", translations: hebrewTranslations },
];

const defaultTranslationName = translations[0].name;

const storageKeyName = "language";

// Replace all whitespace blocks with a single space
function normaliseTextKey(text: string) {
	return text.replace(/\s+/g, " ");
}

function replaceVariables(text: string, variables?: Record<string, unknown>) {
	if (!variables) {
		return text;
	}

	return text.replace(/\{([a-zA-Z0-9_]+)\}/g, (_, variableName: string) => {
		const value = variables[variableName];
		if (value === undefined) {
			throw new Error(`Unknown variable "${variableName}"`);
		}
		return JSON.stringify(value);
	});
}

type I18nProviderProps = {
	readonly children: ReactNode;
};

function I18nProvider({ children }: I18nProviderProps) {
	const [currentLanguage, setCurrentLanguage] = useState(() => {
		const stored = localStorage.getItem(storageKeyName);
		if (stored && translations.some((t) => t.name === stored)) {
			return stored;
		}
		return defaultTranslationName;
	});

	const value = useMemo(
		() => ({
			currentLanguage,
			translate: (text: string, variables?: Record<string, unknown>) => {
				const languageTranslations = (translations.find(
					(t) => t.name === currentLanguage,
				)?.translations ?? {}) as Record<string, string>;
				const key = normaliseTextKey(text);
				const translation = languageTranslations[key];
				if (!translation) {
					if (currentLanguage !== defaultTranslationName) {
						console.warn(
							`No translations for "${key}" in language "${currentLanguage}"`,
						);
					}
					return replaceVariables(text, variables);
				}

				return replaceVariables(translation, variables);
			},
			languageOptions: translations.map((t) => t.name),
			setCurrentLanguage: (newLanguage: string) => {
				setCurrentLanguage(newLanguage);
				localStorage.setItem(storageKeyName, newLanguage);
			},
		}),
		[currentLanguage],
	);
	return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}

function useTranslator() {
	const value = useContext(I18nContext);
	if (value === undefined) {
		throw new Error("useTranslator must be used within a I18nProvider");
	}

	return value.translate;
}

function useTranslationControls() {
	const value = useContext(I18nContext);
	if (value === undefined) {
		throw new Error(
			"useTranslationControls must be used within a I18nProvider",
		);
	}

	return pick(
		value,
		"languageOptions",
		"currentLanguage",
		"setCurrentLanguage",
	);
}

export { useTranslator, useTranslationControls, I18nProvider };
